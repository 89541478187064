.content-empreend-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@keyframes buttonRightLeftShadow {
    from { filter: drop-shadow(0px 0px 0px var(--color--blue--1)) }
}

.list-empreend {
    display: flex;  
    align-items: center;
    justify-content: center; 
    width: 100%;
    overflow: hidden;
    position: relative;
}

ul {
    display: contents;
}

.list {
    transition: transform 0.4s ease-in;    
}

li {
    list-style: none; 
    display: flex;  
    align-items: center;
    justify-content: center;  
}

.empreend-on {
    width: 100%;
    height: auto;        
}

.empreend-on img{
    width: 100%;
    border-color: var(--color--blue--1);
    border-style: solid;
    filter: none;
    border-radius: 18px;
}

.empreend-off {
    box-shadow: 8px 0px 15px 6px var(--color--blue--1);
    width: 45%;    
    height: 155px;
}

.empreend-off img{
    object-fit: cover;  
    height: 160px; 
    filter: blur(1px);
}

.is-hidden{
    display:none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;    
    z-index: 1;
}
  
.modal-content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 18px;
    width: 80%;
    max-width: 1200px;
    cursor: auto;    
}

.modal-content img{
    max-width: 1200px;
    width: 80%;
    border-radius: 18px;
    transition: transform 0.2s;    
}
  
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    font-weight: bold;
    font-size: 36px;
    color: var(--color--blue);
}

.btn-modal-empreend{
    width: 100%;
}

.btn-modal-empreend img {
    width: 100%;
}

.content-360{
    display: flex;
    flex-direction: row;
}

.list-empreend p{
    position: absolute;
    padding: 8px 0;
    bottom: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    font-style: italic;
    font-weight: 500;
    color: var(--color--whiter);
}