/************************* Telas com Resolução Mobile *************************/
@media screen and (min-width: 320px) and (max-width: 519px){

    video {
        width: 320px;
    }

    .map{
        width: 290px !important;
    }

    #bosque img, #paraguacu img{
        width: 140px;

    }

    #bosque, #paraguacu{
        width: 140px;     
        height: 120px;   
    }    

    .content-info_empreendimento{
        height: 490px !important;
        padding: 5px !important;
    }
    
    .text-empreendimento{
        margin: 0 !important;
        padding: 8px 24px !important;
        height: 440px !important;
    }

    .text-empreendimento p{
        font-size: 20px !important;
    }

   
    /*************************************************************************/
    /** De 320 a 360 --- 374 **/
    @media screen and (min-width: 320px) and (max-width: 374px){
        .text-empreendimento p{
            font-size: 16px !important;
        }
    }

    
    /** De 375 a 519 **/
    @media screen and (min-width: 375px) and (max-width: 519px){
        
    }

    
    /* De 390 a 393 **/
    @media screen and (min-width: 390px) and (max-width: 393px){}    

    /** De 390 a 520 **/
    @media screen and (min-width: 390px) and (max-width: 519px){}
    
}

@media screen and (min-width: 320px) and (max-width: 740px){
    footer {
        padding: 20px 15px;        
    }

    .content-section {
        padding: 20px 0 !important;
    }

    .content-infos {
        flex-direction: column;
    }

    hr {
        width: 100%;
    }

    .menu-2 {
        display: flex !important;
        
    }

    .menu {
        display: none !important;
    }  

    .content-info-empreendimentos{
        flex-direction: column;        
    }

    .video-text h3{
        font-size: 15px !important;
    }

    .header-empreendimentos{
        flex-direction: column;
    }

    .content-section{
        width: 90%;
    }

    .text-empreendimento, .content-cards, .content-infos{
        flex-direction: column !important;
    }

    .graus-Img{
        height: 240px !important;
        width: 320px !important;
    }

    .video-empreendimento{
        width: 320px !important;
    }

    #empreendimentos h2{
        padding-left: 30px;
    }
    
    @media screen and (min-width: 340px) and (max-width: 740px){
        .logo-header {
            max-width: 250px !important;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 321px){
        #email, #sobrenome, #telefone, #nome {
            width: 270px;        
        }
    
        .logo-header {
            max-width: 200px !important;
        }

        video {
            width: 290px;
        }
    }   
    
}

@media screen and (min-width: 321px) and (max-width: 1000px){
    #email {
        width: 280px;        
    }
}

@media screen and (min-width: 320px) and (max-width: 1000px){
    .content-section {
        padding: 20px 60px;
    }

    #sobrenome {
        margin-left: 0px;
    }

    form div{
        display: flex;
        flex-direction: column;
    }

    .infos-empreendimentos {
        flex-direction: column;
    }

    .content-info-empreendimentos{
        flex-direction: column;        
    }       
   
}

@media screen and (min-width: 412px) and (max-width: 743px){
    .graus-Img, .video-empreendimento{
        width: 500px !important;
    }
} 


/************************* Telas com Resolução Para Tablet *************************/
/** De 750 a 860**/
@media screen and (min-width: 741px) and (max-width: 860px){
    .google-map {
        margin-left: 70px;
    }

    footer {
        padding: 20px 40px;        
    }

    .menu a {
        padding: 14px  8px !important;
    }

    .menu-2 {
        display: flex !important;
        
    }

    .menu {
        display: none !important;
    } 

    .content-info-empreendimentos{
        flex-direction: column;        
    }

    .graus-Img, .video-empreendimento{
        width: 600px !important;
    }
   
}


/************************* Outras Configurações de Resolução de Tela *************************/
/** Galaxy Fold2 **/
@media screen and (min-width: 880px) and (max-width: 884px){
    .menu a {
        padding: 14px  8px !important;
    }

    .logo-header {
        max-width: 240px !important;
    }

    .content-video {
        margin: auto !important;
    }

    .content-info-empreendimentos{
        flex-direction: column;        
    }    
}

/** Microsoft Surface Duo **/
@media screen and (min-width: 860px) and (max-width: 1240px){  
    .google-map {
        margin-left: 214px;
    }

    .menu a {
        padding: 14px  8px !important;
    }

    .logo-header {
        max-width: 240px !important;
    }

    .content-video {
        margin: auto !important;
    }

    .content-info-empreendimentos{
        flex-direction: column;        
    }

    @media screen and (min-width: 880px) and (max-width: 884px){
        .graus-Img, .video-empreendimento{
            width: 700px !important;
        }
    }

    @media screen and (min-width: 885px) and (max-width: 1240px){
        .graus-Img, .video-empreendimento{
            width: 780px !important;
        }
    }
    
}


/** iPad Mini e Pequenos Tablets  **/
@media screen and (min-width: 741px) and (max-width: 1200px){    

    .text-empreendimento{
        margin: 0 !important;
        padding: 8px 10px;
        height: 340px !important;
    }

    .content-info_empreendimento{
        padding: 20px 10px !important;
        height: 410px;
    }
  
    .content-section{
        width: 90%;
    }

    .text-empreendimento, .content-cards{
        flex-direction: column !important;
    }   

    .cards{
        width: 460px;
        height: 320px;
    }

    .content-infos{
        align-items: center;
    }
}

/** Noteboocks **/
@media screen and (min-width: 741px) and (max-width: 1400px){
    .content-section{
        width: 90%;       
    }
}

/** Outras Configurações **/
@media screen and (min-width: 320px) and (max-width: 1199px){
    .list-empreend p {
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;
    }
    
}




