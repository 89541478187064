.menu {
    padding: 30px 55px;
    background-color: var(--color--whiter);
    height: 66px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;    
}

.logo-header{
    max-width: 300px;
}

.menu a {
    padding: 14px  44px;
    color: var(--color--blue--1);
    cursor: pointer;
}

.link-header:hover  {
    background-image: repeating-linear-gradient(-37deg, var(--color--orange--shadow), var(--color--orange--shadow) 0.02px, transparent 0px, 4px, var(--color--orange--shadow) 31px),
                      repeating-linear-gradient(-9deg, var(--color--orange--shadow), var(--color--orange--shadow) 0.02px, transparent 0px, 4px, var(--color--orange--shadow) 31px),
                      repeating-linear-gradient(130deg, var(--color--orange--shadow), var(--color--orange--shadow) 0.02px, transparent 0px, 4px, var(--color--orange--shadow) 31px),
                      repeating-linear-gradient(170deg, var(--color--orange--shadow), var(--color--orange--shadow) 0.02px, transparent 0px, 4px, var(--color--orange--shadow) 31px);
    background-size: 6px 50%, 
                     50% 6px, 
                     6px 50%, 
                     50% 6px;
    background-position: 0 0, 0 0, 100% 100%, 100% 100%;
    background-repeat: no-repeat;    
    animation: borderAnimationMenu 0.5s;
}

@keyframes borderAnimationMenu {
    from { background-position: 0 100%, -38.82px 0, 100% -38.82px, 0 100%; }
}

.content-header {
    padding: 10px 0;
    margin: 20px 0;
}

.video-text{
    position: absolute;
    background-color: rgb(29, 71, 110, 0.5);
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    text-align: center;
}

.video-text h3{
    font-weight: 600;
    font-size: 26px;
    color: var(--color--orange--shadow);
}

/************** MENU RESPONSIVO **************/
.menu-2 {
    display: none;
    justify-content: normal;
    align-items: center;
    flex-direction: row;
    height: 60px;
    padding: 14px 10px;
}

.menu-2 a {
    color: var(--color--blue--1);
    padding: 10px;
    cursor: pointer;
}

.content-dropdown-menu:hover .dropdown-menu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.btn-dropdown {  
    cursor: pointer;
}

.dropdown-menu {    
    display: none;
    position: absolute;
    background-color: var(--color--whiter);
    min-width: 160px;  
    padding: 8px 16px;    
    z-index: 1;
}

.btn-dropdown svg {
    /* The size of the SVG defines the overall size */
    height: 3em;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
  
.btn-rows {
    fill: none;
    stroke: var(--color--orange--shadow);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-rown-top{
    stroke-dasharray: 12 63;
}
  
.content-dropdown-menu:hover .btn-dropdown{
    transform: rotate(-45deg);
}
  
.content-dropdown-menu:hover .btn-rown-top{
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
}

