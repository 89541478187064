.video-inicial{
   border-radius: 0; 
}

.video-inicial video{
    width: 100%;
    height: 100%;
    display: block;    
}



