.content-infos {
    display: flex;
    flex-direction: row;
    justify-content: space-around;    
}

.infos-contato, .infos-empresa {
    color: var(--color--whiter);
    margin-bottom: 22px;
}

.infos-contato div{
    margin-top: 10px;
}

.content-infos a {
    cursor: pointer;
}

.content-infos img {
    width: 35px;
    padding-top: 4px;
}

.content-infos a:nth-child(2) {
    padding: 18px;
}

.map {
    border: 0;
    width: 500px;
    height: 250px;
}

.devHaveLog p{
    color: var(--color--whiter);
    font-weight: bold;
}

.devHaveLog img{
    width: 220px;
    position: relative;
    top: -20px;
}