#btnTop img{
    width: 50px;
}

#btnTop {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 1;
    cursor: pointer;
    padding: 4px 6px 1px;
    border-radius: 70%; 
    border: 2px solid var(--color--transparent);
}

#btnTop:hover {
    border: 2px solid var(--color--orange--shadow);
  }