/* CLASSEs AND IDs */

/* Classes */
.content-section {
    display: flex;
    flex-direction: column;
    padding: 20px 90px;
    width: 80%;
    min-width: 300px;
    max-width: 1650px;
    margin: auto;
}

.content-form {
    height: 500px;
    display: flex;
    flex-direction: row;    
}

.inputLabel, .btn {    
    border: 1px solid var(--color--blue--1);
    background: transparent;  
    padding: 6px 28px;
    font-size: 18px;  
    margin: 14px 0;
    color: var(--color--blue--1);
    font-family: "Exo 2", sans-serif;
}
  
.inputLabel {     
    height: 36px;   
}

.btn {
    float: right;
    cursor: pointer;
    width: 140px; 
    height: 42px; 
}
  
.btn:hover{
    box-shadow: 6px 3px 10px var(--color--black);
    transition: 0.2s;
}

.content-info-empreendimentos {
    max-width: 1300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 4px 15px;
}

.content-tipo-empe p{
    color: var(--color--whiter);
    font-size: 22px;
    font-weight: bold;
    text-align: center;    
    padding: 5px;
}

.video-empreendimento{
    width: 600px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.header-empreendimentos{
    display: flex;
    flex-direction: column;
    justify-content: space-around;    
    align-items: flex-start;
}

.content-empe, .content-empreendimentos{
    box-shadow: 0 1px 2px 1px var(--color--blue);
}

.content-empe{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 34px 14px;
    margin-top: 20px;
    width: 100%;    
}

.content-empreendimentos{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.cards-header, .cards-footer{
    margin: 10px;
    text-align: center;
    font-size: 24px;
    color: var(--color--whiter);
    font-weight: bold;
}

.cards-footer{
    text-decoration: underline var(--color--orange--shadow);
}

.cards-header i, .cards-footer i{   
    text-shadow: 2px 2px 4px var(--color--orange--shadow);
}

.content-cards{    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.cards{
    width: 260px;
    height: 480px;
    margin: 20px 10px;
    border-radius: 20px;
    background: linear-gradient(9180deg, var(--color--blue) -2%, 
                rgba(0,0,0,0) 12%,
                rgba(0,0,0,0) 40%,
                rgba(0,0,0,0) 80%,
                var(--color--blue) 130%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cards img{
    margin-bottom: 15px;
}

.cards h4{
    color: var(--color--orange--shadow);
    text-align: center;
    margin: 18px 2px;
    font-size: 34px;
}

.cards p{
    text-align: center;
    padding: 0 22px;
}

.ativoEmpe{
    filter: drop-shadow(0 0 2px var(--color--orange--shadow));
    box-shadow: 1px 1px 1px 1px var(--color--orange--shadow);
    border-radius: 30px;
}

.mensagem, .erro{
    font-weight: 600;
}

.mensagem{
    color: green;    
}

.erro{
    color: red;
}

.btnRight{
    left: 16px;
    z-index: 1;
}

.btnLeft{
    right: 16px;
    z-index: 1;
}

.btnRight, .btnLeft {
    
    cursor: pointer;
    position: relative;
}

.btnRight img, .btnLeft img {
    width: 40px;
}

.btnRight img:hover, .btnLeft img:hover {
    filter: drop-shadow(0px 0px 4px var(--color--blue--1));    
    animation: buttonRightLeftShadow 0.8s;    
}

/***********************************************************************************/
/* IDs */
#sobre {
    background-color: var(--color--blue--1);    
}

#sobre h2, #sobre p {
    color: var(--color--whiter);
}

#nome, #sobrenome, #telefone {
    width: 280px;
}

#sobrenome {
    margin-left: 20px;
}

#email {
    width: 639px;
}

#empreendimentos { 
    background-color: var(--color--whiter);
}

#empreendimentos h2{ 
    color: var(--color--blue--1);
    margin-right: 15px;
}

#paraguacu, #bosque{
    cursor: pointer;    
    display: flex;
    align-items: center;
    border: 5px;   
    width: 220px;
    height: 160px;
}

#paraguacu img, #bosque img{
    width: 220px;
    margin-right: 15px;
}

#paraguacu:hover, #bosque:hover{
    filter: drop-shadow(0 0 2px var(--color--orange--shadow));
    box-shadow: 1px 1px 1px 1px var(--color--orange--shadow);
    border-radius: 30px;
}
 
#empreendimentos .content-section{
    width: 100%;
}

