.graus-Img{
    display: block;
    width: 440px;
    height: 337px;
    border: none;
    border-style: none;
    border-radius: 10px;
    margin-bottom: 10px;
}

.graus-Img:active, .graus-Img:selected, .graus-Img:visited { 
    border: none;
    outline: none;
}

.remove-component{
    display: none;
}