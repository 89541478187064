*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {  
  height: 100%;
  width: 100%;
  font-family: "Exo", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2, h3, h4, h5, h6, nav {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--color--blue--1);  
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 22px;
  font-family: "Exo 2", sans-serif;
  font-style: italic;
  font-weight: 600;  
  color: var(--color--whiter);
  margin-bottom: 10px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 20px;
  font-family: "Exo", sans-serif;  
}

p {
  font-size: 16px;
  font-family: "Exo", sans-serif;  
}

nav {
  font-size: 28px;
}

a {
  text-decoration: none;
  color: var(--color--whiter);
}

section, header {  
  max-width: 2850px;    
  margin: auto;
}

form {
  display: flex;
  flex-direction: column;
}

hr {
  color: var(--color--blue--1);
  border: 3px solid;
  width: 100%;
  margin: auto;
}

footer {
  min-height: 400px;
  height: auto;
  padding: 20px 80px;
  background-color: var(--color--blue);
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
}


/*********** SCROLL PAGE ***********/
/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 11px;
  width: 11px;
}
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: var(--color--grey);
  border: 2px solid var(--color--whiter);
}

*::-webkit-scrollbar-track:hover {
  background-color: var(--color--grey);
}

*::-webkit-scrollbar-track:active {
  background-color: var(--color--grey);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--color--blue);
  border: 1px solid var(--color--whiter);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--color--orange--shadow);
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--color--orange--shadow)
}