.content-info_empreendimento{
    display: flex;
    height: 390px;
    width: 100%;
    padding: 20px;  
    flex-direction: row; 
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(84deg, var(--color--blue) 0%, var(--color--blue--1) 48%, var(--color--blue) 100%);
}

.content-text-info{
    display: flex;
    flex-direction: column; 
}

.text-empreendimento{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;  
    height: 320px;
    margin: 5px 15px; 
    padding: 8px 20px;
    font-weight: 600;
    color: var(--color--whiter);  
}

.text-empreendimento h4{
    font-size: 32px;
    font-weight: 800;
    color: var(--color--orange--shadow);
}

.text-empreendimento div{
    display: flex;
    align-items: center;
    flex-direction: column;    
}

.text-empreendimento p {
    text-align: center;
    font-size: 22px;
}

.btn-page-info{
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: var(--color--orange--shadow);
    border-style: none;
    cursor: pointer;
    margin: 5px;
}

.btn-page-info:hover, .activePageTextInfo{  
    transition: 0.4s all;  
    background-color: var(--color--orange--clean);
    box-shadow: 0 0 8px var(--color--orange--shadow);
}

.content-buttons{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin: auto;    
}
