
:root {
    --color--blue: rgb(5, 18, 48); 
    --color--blue--1: rgb(29, 71, 110);
    --color--grey: rgb(217, 217, 217);
    --color--whiter: rgb(255, 255, 255);
    --color--black: rgb(0, 0, 0);
    --color--orange--shadow: rgb(255, 153, 0);
    --color--orange--clean: rgb(255, 206, 0);
    --color--transparent: rgba(0,0,0,0);
    --color--bege: rgb(255, 245, 230);
}